<template>
  <div>
    <LayoutsAppHeader :options="headerOptions" />
    <main :class="{'pb-20': !noBottomPadding}">
      <slot />
    </main>
    <LayoutsAppFooter />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const headerOptions = computed(() => route?.meta?.header);
const background = computed(() => route?.meta?.bg || 'bg-cream');
const noBottomPadding = computed(() => route?.meta?.noBottomPadding);

useHead({
  bodyAttrs: {
    class: background,
  },
  link: [
    {
      rel: 'preconnect',
      href: 'https://dev.visualwebsiteoptimizer.com',
    },
  ],
});
</script>
